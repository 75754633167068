<template>
  <page-content>
    <page-title heading="ข้อมูลแผนการสอน">
      <template v-slot:right>
        <b-button variant="danger" @click="onCreate"
          >เพิ่มแผนการเรียนการสอน</b-button
        >
      </template>
    </page-title>

    <filter-master
      v-if="authUser.isAdminType"
      v-model="filterMaster"
      :m-headquarter-id="
        !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
      "
      :m-division-id="
        !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
      "
      :m-station-id="
        !authUser.canAccessAllStations ? authUser.mStationId : null
      "
      :disabledInput="{
        headQuarter:
          !authUser.canAccessAllHeadQuarters &&
          authUser.mHeadquarterId !== null,
        division:
          !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
        station: !authUser.canAccessAllStations && authUser.mStationId !== null,
      }"
      col-headquarter="4"
      col-division="4"
      col-station="4"
      :hidden-input="{ school: true, schoolClass: true }"
    >
    </filter-master>

    <div class="d-flex flex-row flex-wrap mt-2">
      <b-col cols="6" class="px-2">
        <b-form-group class="mb-0">
          <b-input-group class="input-group-seamless">
            <b-form-input
              :placeholder="
                authUser.isAdminType
                  ? 'ค้นหารายชื่อครูหรือรายชื่อโรงเรียน'
                  : 'ค้นหารายชื่อโรงเรียน'
              "
              v-model="keyword"
              @keyup.enter="onKeywordSearched"
            ></b-form-input>

            <b-input-group-text slot="prepend">
              <font-awesome-icon class="mx-auto" icon="search" />
            </b-input-group-text>

            <b-input-group-text v-show="keyword.length" slot="append">
              <a href="javascript:void(0)" @click="clearKeyword">
                <font-awesome-icon class="mx-auto" icon="window-close" />
              </a>
            </b-input-group-text>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="2" class="px-2">
        <b-button variant="primary" block size="sm" @click="onKeywordSearched"
          >ค้นหา</b-button
        >
      </b-col>
    </div>

    <teaching-dare-table
      ref="table"
      class="mt-4"
      :fields="fields"
      :params="params"
      @edit="onEdit"
      @delete="onDelete"
    />

    <create-or-update-lesson-plan
      :edit-data.sync="editData"
      v-model="showModalLessonPlan"
      @create:success="onCreateSuccess"
    ></create-or-update-lesson-plan>

    <base-delete
      v-model="showModalDelete"
      @delete:success="onDeleteSuccess"
      :delete-data.sync="deleteData"
    ></base-delete>
  </page-content>
</template>

<script>
import { sortBy } from "lodash";
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import CreateOrUpdateLessonPlan from "../../components/modal/CreateOrUpdateLessonPlan";
import TeachingDareTable from "../../components/table/TeachingDare";
import { Auth, TeachingPlan } from "../../models";
import BaseDelete from "../../components/modal/BaseDelete";
import FilterMaster from "../../components/form/FilterMaster";

export default {
  components: {
    PageContent,
    PageTitle,
    CreateOrUpdateLessonPlan,
    TeachingDareTable,
    BaseDelete,
    FilterMaster,
  },

  data() {
    return {
      filterMaster: {},
      keyword: "",
      formfilter: {},
      editData: {},
      deleteData: {},
      showModalLessonPlan: false,
      showModalDelete: false,
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    fields() {
      let fields = [{ key: "station", label: "สถานี", sortable: true }];

      if (this.authUser.isAdminType) {
        fields.push({
          key: "user_name",
          label: "ชื่อ-นามสกุล",
          sortable: true,
        });
      }

      fields = [
        ...fields,
        { key: "school", label: "โรงเรียน", sortable: true },
        // { key: "m_school.m_amphur.m_amphur_name", label: "อำเภอ" },
        // { key: "m_school.m_province.m_province_name", label: "จังหวัด" },
        { key: "yearTerm", label: "ปีการศึกษา", sortable: true },
        { key: "classRoom", label: "ห้องเรียน", sortable: true },
        // {
        //   key: "teaching_plan_count",
        //   label: "แผนการสอน",
        //   class: "text-center",
        // },
        {
          key: "created_at",
          label: "วันที่สร้าง",
        },
      ];

      return fields;
    },

    params() {
      const { filterMaster: params, formfilter } = this;

      if (!this.authUser.isAdminType) {
        this.$set(params, "userProfileId", this.authUser.id);
      }

      return { ...params, ...formfilter };
    },
  },

  methods: {
    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },

    onCreate() {
      this.showModalLessonPlan = true;
    },

    async onEdit(item) {
      this.editData = { ...item };

      if (item.m_station) {
        this.$set(this.editData, "mDivisionId", item.m_station.mDivisionId);

        if (item.m_station.m_division) {
          this.$set(
            this.editData,
            "mHeadquarterId",
            item.m_station.m_division.mHeadquarterId
          );
        }
      }

      if (item.m_school) {
        this.$set(this.editData, "mProvinceId", item.m_school.mProvinceId);
        this.$set(this.editData, "mAmphurId", item.m_school.mAmphurId);
      }

      const promise = await TeachingPlan.api().findAll({
        teachingDareId: item.id,
      });

      const { data = [] } = promise.response.data;

      if (data.length) {
        this.$set(
          this.editData,
          "teaching_plans",
          sortBy(data, "createdAt").map(
            ({ lesson_number, lesson_name, date, time, content }) => ({
              lesson_number,
              lesson_name,
              date,
              time,
              content,
            })
          )
        );
      }

      this.showModalLessonPlan = true;
    },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    onDelete(item) {
      this.deleteData = { ...item };
      this.showModalDelete = true;
    },

    onDeleteSuccess() {
      this.$refs.table.refresh();
    },
  },
};
</script>
