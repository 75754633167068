<template>
  <b-modal
    id="create-or-update-lesson-plan-modal"
    v-model="$show"
    centered
    scrollable
    size="xl"
    header-class="modal__header"
    footer-class="modal__footer"
    body-class="modal__body"
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <template #modal-header="{}">
      <h3 class="font-weight-bold">เพิ่ม/แก้ไขข้อมูลแผนการสอน</h3>
      <span v-if="editData && editData.updatedAt"
        >อัพเดตล่าสุด:
        {{ $_formatDateMixin_formatShort(editData.updatedAt) }}</span
      >
    </template>

    <div class="d-flex flex-row align-items-baseline px-2">
      <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
      <h4 class="font-weight-bold ft-s-18">ข้อมูลห้องเรียน</h4>
    </div>

    <!-- <b-form>
      <div class="d-flex flex-row align-items-baseline px-2">
        <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
        <h4 class="font-weight-bold">ข้อมูลห้องเรียน</h4>
      </div>
      <div class="d-flex flex-row flex-wrap">
        <div class="col-lg-2 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box1">สำนักงาน</label>
          <v-select
            id="box1"
            v-model="dataClassRoom.tmp1"
            :options="optionTest"
          ></v-select>
        </div>
        <div class="col-lg-4 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box2">โรงเรียน</label>
          <v-select
            id="box2"
            v-model="dataClassRoom.tmp2"
            :options="optionTest"
          ></v-select>
        </div>
        <div class="col-lg-2 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box3">ห้องเรียน</label>
          <v-select
            id="box3"
            v-model="dataClassRoom.tmp3"
            :options="optionTest"
          ></v-select>
        </div>
        <div class="col-lg-2 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box4">ปีการศึกษา</label>
          <v-select
            id="box4"
            v-model="dataClassRoom.tmp4"
            :options="optionTest"
          ></v-select>
        </div>
        <div class="col-lg-2 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box5"
            >จำนวนนักเรียนตามแผน</label
          >
          <input
            class="form-control custom__height"
            type="number"
            min="0"
            v-model="dataClassRoom.tmp5"
          />
        </div>
      </div>
    </b-form>

    <div class="d-flex flex-row justify-content-between my-3 px-2">
      <div class="d-flex flex-row align-items-baseline">
        <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
        <h4 class="font-weight-bold">ข้อมูลห้องเรียน</h4>
      </div>
      <b-button
        size="sm"
        type="submit"
        variant="primary"
        @click.prevent="addChapter"
        >เพิ่ม</b-button
      >
    </div>

    <b-card
      v-for="(chapter, index) in chapters"
      :key="`chapter__${index}`"
      class="mb-3"
      header-class="card__header"
    >
      <template #header>
        <div class="d-flex flex-row justify-content-between">
          <h6 style="font-weight: 700">บทที่{{ index + 1 }}.</h6>
          <font-awesome-icon
            icon="window-close"
            class="fa-lg cursor-pointer"
            @click.prevent="deleteChapter(chapter)"
          />
        </div>
      </template>
      <div class="d-flex flex-row flex-wrap mb-2">
        <div class="col-lg-6 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box6">ชื่อบท</label>
          <input
            id="box6"
            class="form-control custom__height"
            type="text"
            v-model="chapter.name"
          />
        </div>
        <div class="col-lg-3 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box7">วันที่เรียน</label>
          <input
            id="box7"
            class="form-control custom__height"
            type="text"
            placeholder="วว/ดด/ปปปป"
            v-mask="'##/##/####'"
            v-model="chapter.date"
          />
        </div>
        <div class="col-lg-3 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box8">เวลาเรียน</label>
          <input
            id="box8"
            class="form-control custom__height"
            type="text"
            placeholder="ชช:นน"
            v-mask="'##:##'"
            v-model="chapter.time"
          />
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="col-12 px-2">
          <label class="font-weight-bold ft-s-14" for="box9">เนื้อหา</label>
          <textarea
            id="box9"
            class="form-control"
            rows="4"
            v-model="chapter.message"
          ></textarea>
        </div>
      </div>
    </b-card> -->

    <filter-master
      v-model="filterMaster"
      :m-headquarter-id="
        editData && editData.mHeadquarterId
          ? editData.mHeadquarterId
          : !authUser.isSuperAdmin
          ? authUser.mHeadquarterId
          : null
      "
      :m-division-id="
        editData && editData.mDivisionId
          ? editData.mDivisionId
          : !authUser.isSuperAdmin
          ? authUser.mDivisionId
          : null
      "
      :m-station-id="
        editData && editData.mStationId
          ? editData.mStationId
          : !authUser.isSuperAdmin
          ? authUser.mStationId
          : null
      "
      :m-school-id="editData.mSchoolId || null"
      :m-class-id="editData.mClassId || null"
      :disabled-input="{
        headQuarter: !authUser.isSuperAdmin,
        division: !authUser.isSuperAdmin,
        station: !authUser.isSuperAdmin,
      }"
      col-headquarter="4"
      col-division="4"
      col-station="4"
      col-school-class="2"
      default-label="-"
      :hidden-input="{ school: true }"
    >
      <template #beforeSchoolClass>
        <b-col cols="10" class="px-0">
          <!-- <div class="d-flex flex-row align-items-baseline px-2">
            <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
            <h4 class="font-weight-bold ft-s-18">ข้อมูลห้องเรียน</h4>
          </div> -->

          <filter-province
            v-model="filterProvince"
            :m-province-id="editData.mProvinceId || null"
            :m-amphur-id="editData.mAmphurId || null"
            :m-school-id="editData.mSchoolId || null"
            default-label="-"
          >
          </filter-province>
        </b-col>
      </template>

      <b-col cols="12" class="px-0">
        <teaching-dare-form
          v-model="formTeachingDare"
          :edit-data="editData"
          :isLesson="true"
          :disabled="isSubmiting"
        ></teaching-dare-form>
      </b-col>
    </filter-master>

    <lesson-form
      v-model="formLesson"
      :isLesson="true"
      :disabled="isSubmiting"
    ></lesson-form>

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="dark"
        :disabled="isSubmiting"
        @click="cancel()"
      >
        ยกเลิก
      </b-button>
      <b-button
        size="sm"
        variant="primary"
        :disabled="isSubmiting"
        @click.prevent="saveLessonPlan()"
      >
        <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner>
        <span v-else>บันทึก</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import lessonMixin from "../../mixins/lessonMixin";
import validatorMixin from "../../mixins/validatorMixin";
import TeachingDareForm from "../form/TeachingDare";
import LessonForm from "../form/Lesson";
import FilterMaster from "../form/FilterMaster";
import FilterProvince from "../form/FilterProvince";
import { TeachingDare } from "../../models/TeachingDare";
import { Auth } from "../../models";

export default {
  mixins: [formatDateMixin, lessonMixin, validatorMixin],

  name: "CreateOrUpdateLessonPlan",

  components: {
    TeachingDareForm,
    LessonForm,
    FilterMaster,
    FilterProvince,
  },

  props: {
    show: Boolean,
    lessonPlanData: Object,
    editData: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "show",
    event: "update",
  },

  data() {
    return {
      isSubmiting: false,
      filterMaster: {
        mHeadquarterId: this.editData.mHeadquarterId || null,
        mDivisionId: this.editData.mDivisionId || null,
        mStationId: this.editData.mStationId || null,
        // mSchoolId: this.editData.mSchoolId || null,
      },
      filterProvince: {
        mProvinceId: this.editData.mProvinceId || null,
        mAmphurId: this.editData.mAmphurId || null,
        mSchoolId: this.editData.mSchoolId || null,
      },
      formTeachingDare: {},
      formLesson: this.$_lessonMixin_makeLesson(),
    };
  },

  watch: {
    "editData.teaching_plans": "updateFormLesson",
  },

  computed: {
    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },

    authUser() {
      return Auth.user();
    },
  },

  methods: {
    updateFormLesson(teachingPlans) {
      if (teachingPlans && teachingPlans.length) {
        this.formLesson = this.$_lessonMixin_makeLesson([...teachingPlans]);
      }
    },

    async saveLessonPlan() {
      const { mStationId, mClassId } = this.filterMaster,
        { mSchoolId } = this.filterProvince;

      const { actual_student, fiscal_year, plan_student, room, term, year } =
        this.formTeachingDare;

      if (
        !mStationId ||
        !mSchoolId ||
        !mClassId ||
        !fiscal_year ||
        !plan_student ||
        !room ||
        !term
      ) {
        return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      }
      // else if (
      //   this.formLesson.length > 0 &&
      //   this.$_validatorMixin_checkNullArr(this.formLesson, [
      //     "lesson_name",
      //     "date",
      //     "time",
      //     "content",
      //   ])
      // ) {
      //   return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      // }

      if (this.formLesson.length > 0) {
        let checkTime;
        for (let index = 0; index < this.formLesson.length; index++) {
          const element = this.formLesson[index].time;
          if (
            this.$_validatorMixin_checkDateTime(element) === "format_incorrect"
          ) {
            this.$toast.error(`[ บทที่${index + 1}. ] ฟอร์แมตเวลาไม่ถูกต้อง`);
            checkTime = false;
            break;
          } else if (
            this.$_validatorMixin_checkDateTime(element) === "equal_time"
          ) {
            this.$toast.error(
              `[ บทที่${index + 1}. ] เวลาเริ่มต้นและเวลาสิ้นสุด ต้องไม่เท่ากัน`
            );
            checkTime = false;
            break;
          } else if (
            this.$_validatorMixin_checkDateTime(element) === "wrong_time"
          ) {
            this.$toast.error(
              `[ บทที่${index + 1}. ] เวลาเริ่มต้นต้องน้อยกว่าเวลาสิ้นสุด`
            );
            checkTime = false;
            break;
          }
          checkTime = true;
        }
        if (!checkTime) return;
      }

      // for (let index = 0; index < this.formLesson.length; index++) {
      //   this.formLesson[index].lesson_number = `${index + 1}`;
      // }

      const body = {
        actual_student: actual_student ? actual_student : 0,
        plan_student: plan_student ? plan_student : 0,
        room,
        term,
        mStationId,
        mSchoolId,
        mClassId,
        fiscal_year: fiscal_year.toString(),
        year: year.toString(),
        teaching_plans: this.formLesson,
      };

      let promise;

      this.isSubmiting = true;

      if (this.editData && this.editData.id) {
        promise = await TeachingDare.api().updatePlan(this.editData.id, body);
      } else {
        promise = await TeachingDare.api().createPlan({
          ...body,
          userProfileId: Auth.user().id,
        });
      }

      this.isSubmiting = false;

      const { data } = promise.response;

      if (data && data.response_status == "SUCCESS") {
        this.$toast.success(
          `${
            this.editData && this.editData.id ? "อัพเดต" : "สร้าง"
          }ข้อมูลแผนการสอนเรียบร้อยแล้ว`
        );
        this.$bvModal.hide("create-or-update-lesson-plan-modal");
        this.$emit("create:success");
      } else {
        if (data.message) {
          this.$toast.error(data.message);
        } else {
          this.$toast.error(
            `${
              this.editData && this.editData.id ? "อัพเดต" : "สร้าง"
            }ข้อมูลแผนการสอนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
          );
        }
      }
    },

    onHidden() {
      this.clearData();
    },

    clearData() {
      this.formTeachingDare = {};

      this.formLesson = this.$_lessonMixin_makeLesson();

      this.$emit("update:editData", {});
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border: 1px solid #d1d2db;
  border-radius: 0.29rem;
}
.modal__header {
  border-bottom: none !important;
}
.modal__footer {
  border-top: none !important;
}
.modal__body {
  overflow-x: hidden !important;
}
.card__header {
  padding-bottom: 0;
}
</style>
